<template>
    <form @submit.prevent="login(!v$.$invalid)" class="p-fluid">
        <fieldset :disabled="loadingLogin">
            <div class="form-group">
                <div class="flex align-items-center justify-content-center flex-column">
                    <div class="field w-10 lg:w-6 mb-3 mt-3">
                        <div class="p-float-label p-input-icon-right">
                            <i class="pi pi-envelope" />
                            <InputText id="email" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted}" aria-describedby="email-error"/>
                            <label for="email" :class="{'p-error':v$.email.$invalid && submitted}">Email*</label>
                        </div>
                        <span v-if="v$.email.$error && submitted">
                            <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                            <small class="p-error">{{error.$message}}</small>
                            </span>
                        </span>
                        <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{v$.email.required.$message.replace('Value', 'Email')}}</small>
                    </div>
                     <div class="field w-10 lg:w-6 mb-3 mt-3">
                        <div class="p-float-label">
                            <Password id="password" v-model="v$.password.$model" :class="{'p-invalid':v$.password.$invalid && submitted}" toggleMask :feedback="false"></Password>
                            <label for="password" :class="{'p-error':v$.password.$invalid && submitted}">Password*</label>
                        </div>
                        <small v-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response" class="p-error">{{v$.password.required.$message.replace('Value', 'Password')}}</small>
                    </div>
                    <Button type="submit" label="Masuk" icon="pi pi-user" class="w-10 lg:w-6 mb-3 mt-3">
                        <ProgressSpinner v-if="loadingLogin" style="width:20px;height:20px" strokeWidth="8" animationDuration=".5s" />
                    </Button>
                </div>
            </div>
        </fieldset>
    </form>
    <div class="mt-3 text-center">
        <label class="block text-1xl font-semibold font-medium mb-3">Belum punya akun? </label><Button label="Daftar" class="p-button-info p-button-text" @click="openRegister" />
    </div>
    <!-- Dialog Register -->
    <Dialog v-model:visible="register" :style="{width: '600px'}" header="Register User" :modal="true" class="p-fluid">
        <form @submit.prevent="registerUser" class="p-fluid">
            <fieldset :disabled="loadingRegister">
                <div class="form-group">
                    <div class="field">
                        <label for="nama">Nama*</label>
                        <InputText id="nama" v-model="nama" :class="{'p-invalid':submitted && !nama || errors.nama}" />
                        <small class="p-error" v-if="submitted && !nama">Nama harus diisi.</small>
                        <InlineMessage  v-if="errors && errors.nama"><strong class="p-error">{{ errors.nama[0] }}</strong></InlineMessage>
                    </div>
                    <div class="field">
                        <label for="email">Email*</label>
                        <InputText id="email" v-model="email" :class="{'p-invalid':submitted && !email || errors.email}" @focus="resetErrorEmail" />
                        <small class="p-error" v-if="submitted && !email">Email harus diisi.</small>
                        <InlineMessage  v-if="errors && errors.email"><strong class="p-error">{{ errors.email[0] }}</strong></InlineMessage>
                    </div>
                    <div class="field">
                        <label for="password">Password*</label>
                        <Password id="password" v-model="password"  toggleMask :feedback="false" :class="{'p-invalid':submitted && !password  || errors.password}" />
                        <small class="p-error" v-if="submitted && !password">password harus diisi.</small>
                        <InlineMessage  v-if="errors && errors.password"><strong class="p-error">{{ errors.password[0] }}</strong></InlineMessage>
                    </div>
                    <div class="field">
                        <label for="password_confirmation">Konfirmasi Password*</label>
                        <Password id="password_confirmation" v-model="password_confirmation"  toggleMask :feedback="false" :class="{'p-invalid':submitted && !password_confirmation  || errors.password}" />
                        <small class="p-error" v-if="submitted && !password_confirmation">Konfirmasi password harus diisi.</small>
                        <small class="p-error" v-if="password != password_confirmation">Konfirmasi password tidak sesuai.</small>
                    </div>
                    <div class="field">
                        <label for="Role" class="mb-3">Role</label>
                        <Dropdown id="role" v-model="role" :options="roles" optionLabel="label" optionValue="value" placeholder="Pilih role" required/>
                    </div>
                </div>
            </fieldset>
        </form>
        <template #footer>
            <Button label="Batalkan" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="newUser" :disabled="!password_confirmation || !password || !email || !nama" />
        </template>
    </Dialog>
    <!-- Dialog warning non-aktif user -->
    <Dialog class="warning-dialog" header="Error" v-model:visible="akunNonaktif" :breakpoints="{ '960px': '80vw' }" :style="{ width: '30vw' }" modal draggable ariaCloseLabel>
        <div class="flex align-items-center flex-column pt-6 px-3">
            <i class="pi pi-exclamation-triangle" :style="{fontSize: '5rem', color: 'var(--orange-500)' }"></i>
            <h5>Akun anda belum aktif!</h5>
            <p :style="{lineHeight: 1.5, textIndent: '1rem'}" class="text-center">
                {{ errors.status[0] }}
            </p>
        </div>
        <template #footer>
            <div class="flex justify-content-center">
                <Button label="OK" @click="toggleDialog" class="p-button-text" />
            </div>
        </template>
    </Dialog>
    <!-- Dialog sukses pendaftaran user -->
    <Dialog header="Pendaftaran Berhasil" v-model:visible="pendaftaranBerhasil" :breakpoints="{ '960px': '80vw' }" :style="{ width: '30vw' }" modal draggable ariaCloseLabel>
        <div class="flex align-items-center flex-column pt-6 px-3">
            <i class="pi pi-exclamation-circle" :style="{fontSize: '5rem', color: 'var(--green-500)' }"></i>
            <h5>Anda berhasil mendaftarkan akun</h5>
            <p :style="{lineHeight: 1.5, textIndent: '1rem'}" class="text-justify">
                Akun anda dengan nama <i><b>{{ nama }}</b></i>  dan email <i><b>{{ email }}</b></i> berhasil terdaftar. Namun akun anda belum dapat digunakan karena belum diaktivasi oleh Super Admin. Mohon menghubungi Super Admin untuk mengaktifkan dan mendapatkan info lebih lanjut.
            </p>
        </div>
        <template #footer>
            <div class="flex justify-content-center">
                <Button label="OK" @click="toggleDialog" class="p-button-text" />
            </div>
        </template>
    </Dialog>

</template>

<script>
    import User from '../api/User'
    import useVuelidate from '@vuelidate/core'
    import { required, email } from '@vuelidate/validators'

    export default {
        name: 'Login',
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                form: {},
                nama: '',
                email: '',
                password: '',
                password_confirmation: '',
                role: '2',
                status: '0',
                errors: {},
                loadingLogin: false,
                loadingRegister: false,
                successLogin: false,
                errorLogin: false,
                submitted: false,
                akunNonaktif: false,
                pendaftaranBerhasil: false,
                register: false,
                roles: [
                    {label: 'Amil Sr.', value: '2'},
                    {label: 'Admin', value: '3'},
                ],
            }
        },
        methods: {
            toggleDialog() {
                this.akunNonaktif = false
                this.pendaftaranBerhasil = false
            },
            login(isFormValid) {
                this.submitted = true;
                if (!isFormValid) {
                    return;
                }
                this.form.email = this.email
                this.form.password = this.password
                this.loadingLogin = true
                User.login(this.form)
                    .then(response => {
                        this.reset()
                        this.successLogin = true
                        this.$router.push({path: '/'})
                    })
                    .catch(error => {
                        this.errorLogin = true
                        if (!error.response) {
                            this.networkError()
                        } else if(error.response.status == 422) {
                            this.errors = error.response.data.errors
                            if (this.errors.email) {
                                this.$toast.add({severity:'error', summary: 'Error', detail: this.errors.email[0], life: 3000});
                            }
                            if (this.errors.password) {
                                this.$toast.add({severity:'error', summary: 'Error', detail: this.errors.password[0], life: 3000});
                            }
                            if (this.errors.status) {
                                this.akunNonaktif = true
                            }
                        }
                    }).finally(() => {
                        this.loadingLogin =  false
                    });
            },
            reset(){
                this.successLogin = false;
                this.errorLogin = false;
            },
            showError() {
                this.$toast.add({severity:'error', summary: 'Error', detail:'Message Content', life: 3000});
            },
            networkError() {
                this.$confirm.require({
                    message: 'Tidak bisa menghubungi server. Coba refresh halaman.',
                    header: 'Network error',
                    icon: 'pi pi-exclamation-triangle',
                    blockScroll: true,
                    defaultFocus: "accept",
                    accept: () => {
                        this.$router.go()
                    },
                    reject: () => {
                        this.$confirm.close();
                    }
                });
            },
            openRegister() {
                this.register = true
            },
            hideDialog() {
                this.register = false
            },
            newUser() {
                this.submitted = true;
                this.loadingRegister = true
                this.form = {
                    nama: this.nama,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    role: this.role,
                    status: this.status,
                }
                User.register(this.form)
                    .then(async response => {
                        this.successRegister = true;
                        this.register = false
                        this.pendaftaranBerhasil = true
                        this.form = {
                            nama: '',
                            email: '',
                            password: '',
                            password_confirmation: '',
                            role: '2',
                            status: '0'
                        }
                    })
                    .catch(error => {
                        this.errorRegister = true
                        if (!error.response) {
                            this.networkError()
                        } else if(error.response.status == 422) {
                            this.errors = error.response.data.errors
                        }
                    }).finally(() => {
                        this.loadingRegister =  false
                    });
                },
                resetErrorEmail() {
                    delete this.errors.email
                }
        },
        validations() {
            return {
                email: {
                    required,
                    email
                },
                password: {
                    required
                }
            }
        },
        mounted() {
        }
    }
</script>
